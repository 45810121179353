import React from "react";
import { graphql, Link } from "gatsby";
import BlogPostCard from "components/BlogPostCard";
import Layout from "components/Layout";
import Pagination from "components/Pagination";

const BlogListMain = ({ data, pageContext }) => {
  // Generate the previous and next page URLs.
  const previousPage =
    pageContext.currentPage === 2
      ? "/blogs/"
      : `/blogs/${pageContext.currentPage - 1}`;
  const nextPage = `/blogs/${pageContext.currentPage + 1}`;

  return (
    <Layout>
      <section className="container my-5">
        <ul className="row list-unstyled">
          {data.posts.edges.map((edge) => (
            <li key={edge.node.id} className="col-lg-4 mb-3">
              <BlogPostCard
                slug={edge.node.fields.slug}
                title={edge.node.frontmatter.title}
                date={edge.node.frontmatter.date}
                excerpt={edge.node.excerpt}
                blogImageSRC={data.blogImage.childImageSharp.gatsbyImageData}
                readingTime={edge.node.fields.readingTime.text}
              />
            </li>
          ))}
        </ul>
        <div className="pagination">
          {pageContext.currentPage > 1 && (
            <Link to={previousPage}>&lt;&lt; Previous Page</Link>
          )}
          {pageContext.currentPage < pageContext.numPages && (
            <Link to={nextPage}>Next Page &gt;&gt;</Link>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default BlogListMain;

// The page query.
export const blogListQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    posts: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
    blogImage: file(name: { eq: "blog-article-default" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
